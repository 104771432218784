import * as React from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

/** Custom Components */
import { COMM_URL, REGISTER_URL, ROLES_URL, SITES_URL } from '../config/config';
import { Grid } from '@mui/material';
import { sha256 } from 'js-sha256';

class RegisterComponent extends React.Component {  

	constructor(props){
		super(props);
		this.state = {
			name: '',
			username : '',
			role : '',
			site : localStorage.getItem("site"),
			sites : [],
			roles: [],
			committee: '',
			committeelist: [],
		}
	}

	componentDidMount = () =>{
		this.getSites();		
		this.getRoles();
		this.getCommittee();
	}

	getSites = () =>{
		let data = JSON.stringify({'flag':''})
		fetch(SITES_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum' : sha256(data)
			},
			body : JSON.stringify({"data": btoa(data)})
		})
		.then(res => res.json())
		.then(res => {
			if(res.DATA){
				this.setState({
					sites : res.DATA
				});
			}
		})
		.catch(error => {
			//alert('Error in loading data !');
		})

	}

	getRoles = () =>{
		fetch(ROLES_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum': sha256('')
			}
		})
		.then(res => res.json())
		.then(res => {
			if(res.DATA){
				this.setState({
					roles : res.DATA
				});
			}
		})
		.catch(error => {
			//alert('Error in loading data !');
		})
	}

	getCommittee = () =>{
		let data = JSON.stringify({site: localStorage.getItem('site')});
		fetch(COMM_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum': sha256(data)
			},
			body: JSON.stringify({"data":btoa(data)})
		})
		.then(res => res.json())
		.then(res => {
			this.setState({
				committeelist : res.DATA
			});
		})
		.catch(error => {
			//alert('Error in loading data !');
		})
	}

	onChangeUsername = (event) =>{
		this.setState({username : event.target.value});
	}
	onChangeName = (event) =>{
		this.setState({name : event.target.value});
	}
	onChangeRole = (event) =>{
		this.setState({role : event.target.value});
	}
	onChangeCommittee = (event) =>{
		this.setState({committee : event.target.value});
	}


	submitForm = () =>{
		if(this.state.username.length === 0 ){
			alert("Username is required !");
			return false;
		}
		if(this.state.role.length === 0 ){
			alert("Role is required !");
			return false;
		}
		if(this.state.name.length === 0 ){
			alert("Name is required !");
			return false;
		}
		let data = JSON.stringify({
			name : this.state.name,
			username : this.state.username,
			committee: this.state.committee, 
			role: this.state.role, 
			created_by : localStorage.getItem('user'),
			site_code : localStorage.getItem('site')
		});
		fetch(REGISTER_URL,{
				method:'POST',
				headers: {
					'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
					'Content-type' : 'application/json',
					'Checksum' : sha256(data)
				},
				body : JSON.stringify({"data":btoa(data)})
			})
			.then(res => res.json())
			.then(res => {
				if(res.STATUS === 0){
					alert("User registered. Please use the username as temporary password!");
				}
				else
					alert(res.MESSAGE);
					this.setState({name : '',username:'',role:''});
				})
			.catch(error => {
				alert('Error:: Unable to save user!!');
				this.setState({name : '',username:'',role:''});
			})
	}


	render() {  
		const { sites,site,roles,committeelist } = this.state;
		return (
			<>
				<Card>
					<Typography gutterBottom variant="h6" component="div" textAlign={'center'}>Register User</Typography>								
					<Grid container spacing={2}>
						<Grid item md={12} xs={12}>
							<Grid container spacing={2} className="m-1">
								<Grid item md={2}></Grid>
								<Grid item xs={10} md={6}>
									<FormControl fullWidth>
										<InputLabel id="site_code">Site</InputLabel>
										<Select label="site" onChange={this.onChangeSite} defaultValue={site} disabled>
											{ sites.map(item=>
												<MenuItem value={item.site_code} key={item.site_code}>{item.site_name}</MenuItem>
											)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container spacing={2} className="m-1">
								<Grid item md={2}></Grid>
								<Grid item xs={10} md={6}>
									<FormControl fullWidth>
										<InputLabel id="role">Role</InputLabel>
										<Select label="role" onChange={this.onChangeRole} defaultValue="">
											{ roles.map(item=>
												<MenuItem value={item.role_name} key={item.role_name}>{item.description}</MenuItem>
											)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container spacing={2} className="m-1">
								<Grid item md={2}></Grid>
								<Grid item xs={10} md={6}>
									<FormControl fullWidth>
										<InputLabel id="committee">Committee</InputLabel>
										<Select label="committee" onChange={this.onChangeCommittee} defaultValue="">
											<MenuItem value="" key="0"> None</MenuItem>
											{ committeelist.map(item=>
												<MenuItem value={item.committee_name} key={item.committee_name}>{item.committee_name}({item.description})</MenuItem>
											)}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid container spacing={2} className="m-1">
								<Grid item md={2}></Grid>
								<Grid item xs={10} md={6}>
									<TextField fullWidth label="Username" value={this.state.username} inputProps={{maxLength:15}} onChange={this.onChangeUsername}/>
								</Grid>
							</Grid>
							<Grid container spacing={2} className="m-1">
								<Grid item md={2}></Grid>
								<Grid item xs={10} md={6}>
									<TextField fullWidth label="Name" value={this.state.name} onChange={this.onChangeName}/>
								</Grid>
							</Grid>
							<Grid container spacing={2} className="m-1">
								<Grid item md={8} textAlign={'right'}>
								<Button  variant="contained" color="success" sx={{margin:'10px'}} onClick={this.submitForm}>Save Changes</Button>
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Card>
  			</>
		)
	}

} 
export default RegisterComponent