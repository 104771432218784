import * as React from 'react';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

//import QRCode from "react-qr-code";
import QRCode from 'qrcode'

/** Custom Components */
import { Container } from 'react-bootstrap'; 
import { RATES_URL, SITE_DETAIL_URL, TICKET_DATA_URL } from '../config/config';
import { Grid, TableFooter } from '@mui/material';
import { sha256 } from 'js-sha256';

class TicketComponent extends React.Component {  
	constructor(props){
		super(props);
		this.state = {
			ep_ref_no : props.data,
			detail: {
				site_code: '',
				ep_ref_no: '',
				entry_date: '',
				no_of_adults: 0,
				no_of_children: 0,
				no_of_foreign_adults: 0,
				no_of_foreign_children: 0,
				amount: 0,
				entered_by: '',
				mobile_no: ''
			},
			rates : {
				DA: 0,
				DC: 0,
				FA: 0,
				FC: 0
			},
			site : {},
			qr : null
		}
	}

	componentDidMount = () =>{
		this.getTicket();
		QRCode.toDataURL(this.state.ep_ref_no,{quality: 0.3})
		.then(url => {
			this.setState({qr: url});
		})
		.catch(err => {
			console.error(err)
		})
	}

	getTicket = () =>{
		let data = JSON.stringify({ep_ref_no : this.props.data,site: localStorage.getItem('site')});
		fetch(TICKET_DATA_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum' : sha256(data)
			},
			body : JSON.stringify({"data": btoa(data)})

		})
		.then(res => res.json())
		.then(res => {
			if(res.STATUS === 0){
				this.setState({detail: res.DATA});
				this.getSiteDetail(res.DATA.site_code)
				this.getRates(res.DATA.site_code)
			}
			else{
				alert("Error loading ticket")
			}
		})
		.catch(error => {
			alert('Error:: Unable to find ticket!!');
		})
	}

	/** Site Details */
	getSiteDetail = (site) =>{
		let data = JSON.stringify({site: site})
		fetch(SITE_DETAIL_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum' : sha256(data)
			},
			body : JSON.stringify({"data": btoa(data)})

		})
		.then(res => res.json())
		.then(res => {
			if(res.STATUS === 0){
				this.setState({site: res.DATA})
				return false;
			}
			else{
				alert("Error getting site information");
			}
		})
		.catch(error => {
			alert('Error !');
			return false;
		})
	}

	/** Get Rates */
	getRates = () =>{
		let data = JSON.stringify({site: localStorage.getItem('site')});
		fetch(RATES_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum' : sha256(data)
			},
			body: JSON.stringify({"data":btoa(data)})

		})
		.then(res => res.json())
		.then(res => {
			this.setState({rates: res.DATA})
			return false;
		})
		.catch(error => {
			alert('Error:: Unable to find rates!!');
			return false;
		})
	}

	render() { 
		const {ep_ref_no, detail,rates,site,qr} = this.state; 
		//const value = ep_ref_no;

		let da_rate = rates.DA;
		let dc_rate = rates.DC;
		let fa_rate = rates.FA;
		let fc_rate = rates.FC;

		return (
			<Container>
				<Card sx={{ minWidth: 255, width: 315, padding: 2 }} id="ticket">
					<Typography sx={{fontSize: '15px',textAlign:'center',fontFamily:'Helvetica'}}>
						{site.site_name}<br/>{site.address}
					</Typography>								
					<Grid container spacing={1}>
						<Grid item sm={12} xs={12}>
							<Typography textAlign='center'sx={{fontWeight:'bold',fontSize:'14px'}}>Entry Ticket</Typography>
						</Grid>
					</Grid>
					<Grid container spacing={1}>
						<Grid item sm={6} xs={6} textAlign='left' >
							<Typography sx={{fontSize:'14px',fontWeight:'bold'}}> Date {detail.entry} {detail.reprint==='R'?'(R)':''} </Typography>
						</Grid>
						<Grid item sm={6} xs={6} textAlign='right' >
							<Typography gutterBottom variant="p" component="div" sx={{fontSize:'14px',fontWeight:'bold'}}>
								No. {ep_ref_no}
							</Typography>
						</Grid>
					</Grid>
						<Table aria-label="simple table">
							<TableHead>
								<TableRow className='table-header'>
									<TableCell sx={{padding:'2px'}}>Type</TableCell>
									<TableCell sx={{padding:'2px',textAlign:'center'}}>No.</TableCell>
									<TableCell sx={{padding:'2px',textAlign:'right'}}>Amt(Rs.)</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								<TableRow>
									<TableCell sx={{padding:'2px'}}>Adult</TableCell>
									<TableCell sx={{padding:'2px',textAlign:'center'}}>{detail.no_of_adults}</TableCell>
									<TableCell sx={{padding:'2px',textAlign:'right',paddingRight:'10px'}}>{detail.no_of_adults * da_rate}</TableCell>
								</TableRow>								
								<TableRow>
									<TableCell sx={{padding:'2px'}}>Child</TableCell>
									<TableCell sx={{padding:'2px',textAlign:'center'}}>{detail.no_of_children}</TableCell>
									<TableCell sx={{padding:'2px',textAlign:'right',paddingRight:'10px'}}>{detail.no_of_children * dc_rate}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{padding:'2px'}}>Adult(Foreigner)</TableCell>
									<TableCell sx={{padding:'2px',textAlign:'center'}}>{detail.no_of_foreign_adults}</TableCell>
									<TableCell sx={{padding:'2px',textAlign:'right',paddingRight:'10px'}}>{detail.no_of_foreign_adults * fa_rate}</TableCell>
								</TableRow>
								<TableRow>
									<TableCell sx={{padding:'2px'}}>Child(Foreigner)</TableCell>
									<TableCell sx={{padding:'2px',textAlign:'center'}}>{detail.no_of_foreign_children}</TableCell>
									<TableCell sx={{padding:'2px',textAlign:'right',paddingRight:'10px'}}>{detail.no_of_foreign_children * fc_rate}</TableCell>
								</TableRow>												
							</TableBody>
							<TableFooter>
								<TableRow>
									<TableCell sx={{padding:'2px',fontWeight:'bold',fontSize:'14px'}}>Total</TableCell>
									<TableCell sx={{padding:'2px',fontWeight:'bold',textAlign:'center',fontSize:'14px'}} >{detail.count}</TableCell>
									<TableCell sx={{padding:'2px',fontWeight:'bold',textAlign:'right',paddingRight:'10px',fontSize:'14px'}}>Rs.{detail.amount}</TableCell>
								</TableRow>
							</TableFooter>
						</Table>
						<Grid container spacing={2}>
						<Grid item sm={12} xs={12}>
						<div style={{ height: "auto", margin: "0 auto", maxWidth: 100 }} >
							<img src={qr} alt="QR Code"/>
						</div>
						</Grid>
					</Grid>
					<Typography gutterBottom variant="p" component="div" align='center' sx={{fontSize:'10px'}}>
						Keep ticket till exit time<br/>
						Timing: {site.time} <br/>
						Thanks for your visit.<br/>
						Issued by {detail.entered_by}
					</Typography>															
				</Card>
			</Container>
		)
	}

} 
export default TicketComponent