import * as React from 'react';
import { Link  } from 'react-router-dom';  

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableFooter from '@mui/material/TableFooter';

import { RATES_URL, TICKET_SAVE_URL, SITES_URL, GUEST_URL } from '../config/config';
import { sha256 } from 'js-sha256';


/** Custom Components */
import { Container } from 'react-bootstrap'; 
import { Grid, IconButton } from '@mui/material';
import { MdCurrencyRupee, MdHome } from 'react-icons/md';

class SelfEntryComponent extends React.Component {  
	constructor(props){
		super(props);
		let today = new Date().getFullYear()+'-'+String(new Date().getMonth()+1).padStart(2,'0')+'-'+String(new Date().getDate()).padStart(2,'0');
		this.state = {
			displayform : true,
			adult : '',
			children : '',
			fadult : '',
			fchildren : '',
			site : '',
			mobile : '',
			amount : 0,
			ep_ref_no : '',
			rates : {
				FA : 0,
				FC : 0,
				DA : 0,
				DC : 0
			},
			sites : [],
			date: today
		};
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	componentDidMount = () =>{

		fetch(GUEST_URL,{
			method:'POST',
			headers: {
				'Content-type' : 'application/json'
			}
		})
		.then(res => res.json())
		.then(res => {
			localStorage.setItem('ACCESS_TOKEN',res.TOKEN);
		})
		.catch(error => {
			//alert('Error in loading data !');
		})
		this.getSites();
	}

	getSites = () =>{
		let data = JSON.stringify({flag: 'P'});
		fetch(SITES_URL,{
			method:'POST',
			headers: {
				'Content-type' : 'application/json',
				'Checksum' : sha256(data)
			},
			body : JSON.stringify({ 'data' : btoa(data)})
		})
		.then(res => res.json())
		.then(res => {
			if(res.DATA){
				this.setState({
					sites : res.DATA
				});
			}
		})
		.catch(error => {
			alert('Error in loading data !');
		})
	}

	getRates = () =>{
		let data = JSON.stringify({
			site : this.state.site
		});
		fetch(RATES_URL,{
			method:'POST',
			headers: {
				'Content-type' : 'application/json',
				'Checksum' : sha256(data),
				'Authorization' : localStorage.getItem('ACCESS_TOKEN')
			},
			body : JSON.stringify({ 'data' : btoa(data)})
		})
		.then(res => res.json())
		.then(res => {
			if(res.DATA){
				this.setState({rates: res.DATA})
				return false;
			}
		})
		.catch(error => {
			alert('Error:: Unable to find rates!!');
			return false;
		})
	}
	onChangeSite = (event) =>{
		localStorage.setItem('site',event.target.value)
		this.setState({site: event.target.value});
		this.getRates();
	}
	onChangeDate = (event) =>{
		this.setState({date: event.target.value});
	}
	onChangeAdultCount = (event) =>{
		if(this.state.site === ''){
			alert("Select a site!");
			return;
		}
		if(event.target.value < 0)
			event.target.value = 0;
		let {rates, children,fadult, fchildren} = this.state
		let total = rates.FA*fadult + rates.FC*fchildren + rates.DA*event.target.value + rates.DC*children;
		this.setState({adult : event.target.value, amount: total});
	}
	onChangeFAdultCount = (event) =>{
		if(this.state.site === ''){
			alert("Select a site!");
			return;
		}
		if(event.target.value < 0)
			event.target.value = 0;
		let {rates, adult, children, fchildren} = this.state
		let total = rates.FA*event.target.value + rates.FC*fchildren + rates.DA*adult + rates.DC*children;
		this.setState({fadult : event.target.value, amount: total});
	}
	onChangeChildrenCount = (event) =>{
		if(this.state.site === ''){
			alert("Select a site!");
			return;
		}
		if(event.target.value < 0)
			event.target.value = 0;
		let {rates, fadult, adult, fchildren} = this.state
		let total = rates.FA*fadult + rates.FC*fchildren + rates.DA*adult + rates.DC*event.target.value;
		this.setState({children : event.target.value, amount: total});
	}
	onChangeFChildrenCount = (event) =>{
		if(this.state.site === ''){
			alert("Select a site!");
			return;
		}
		if(event.target.value < 0)
			event.target.value = 0;
		let {rates, adult, children,fadult} = this.state
		let total = rates.FA*fadult + rates.FC*event.target.value + rates.DA*adult + rates.DC*children;
		this.setState({fchildren : event.target.value,amount: total});
	}
	onChangeMobile = (event) =>{
		if(this.state.site === ''){
			alert("Select a site!");
			return;
		}
		this.setState({mobile : event.target.value});
	}


	handleSubmit = (event) =>{
		event.preventDefault();

		if((this.state.adult === 0 || this.state.adult === '')
			&&(this.state.children === 0 || this.state.children === '')
			&&(this.state.fadult === 0 || this.state.fadult === '')
			&&(this.state.fchildren === 0 || this.state.fchildren === '')
			){
				alert('Invalid Information!');
				return false;
			}
		let adult = (this.state.adult === '')?0:this.state.adult;
		let children = (this.state.children === '')?0:this.state.children;
		let fadult = (this.state.fadult === '')?0:this.state.fadult;
		let fchildren = (this.state.fchildren === '')?0:this.state.fchildren;
	
		let data = JSON.stringify({
			'user' : 'guest',
			'no_of_adults' : adult,
			'no_of_children' : children,
			'no_of_fadults' : fadult,
			'no_of_fchildren' : fchildren,
			'site' : this.state.site,
			'mobile' : this.state.mobile,
			'amount' :this.state.amount ,
			'date' : this.state.date
		});
		fetch(TICKET_SAVE_URL,{
				method:'POST',
				headers: {
					'Content-type' : 'application/json',
					'Checksum' : sha256(data),
					'Authorization' : localStorage.getItem('ACCESS_TOKEN')
				},
				body : JSON.stringify({ 'data' : btoa(data)})
			})
			.then(res => res.json())
			.then(res => {
				if(res.STATUS === 0){
					this.setState({ep_ref_no: res.PASS_NO})
					this.props.history(`/printticket/${res.PASS_NO}`);
					return false;
				}
				else{
					alert("Unable to generate ticket");
				}
		})
		.catch(error => {
				alert('Error:: Could not generate Ticket!!');
				return false;
			})
	}
	showConfirm = () =>{

		if((this.state.adult === '' || this.state.adult === '0' || this.state.adult === 0)
			&&(this.state.children === '' || this.state.children === '0' || this.state.children === 0)
			&&(this.state.fadult === '' || this.state.fadult === '0' || this.state.fadult === 0)
			&&(this.state.fchildren === '' || this.state.fchildren === '0' || this.state.fchildren === 0)){
				alert("Invalid Information");
				return false;
		}
		this.setState({displayform:false})
	}

	resetForm = () =>{
		this.setState({
			adult : '',
			children : '',
			fadult : '',
			fchildren : '',
			amount : 0 
		});
	}

	render() { 
		var {rates, adult, fadult, children, fchildren, amount, sites, date} = this.state; 
		if(adult === '')
			adult = 0;
		if(fadult === '')
			fadult = 0;
		if(children === '')
			children = 0;
		if(fchildren === '')
			fchildren = 0;
			
		let total = parseInt(adult) + parseInt(fadult) + parseInt(children) + parseInt(fchildren);
		return (
			<>
				<Container>
					<Card sx={{ minHeight:'80vh',minWidth: 275, padding: 2 }}>
						
							<Grid container className='page-title'>
								<Grid item md={10} xs={8}>
									<Typography gutterBottom variant="h6" component="div">
										Details of visitors
									</Typography></Grid>
								<Grid item md={2} textAlign={'right'}>
									<Link to="/" title='Back to Homepage'>
										<Typography component='span' sx={{fontSize:'26px',fontWeight:'bold'}}><MdHome/></Typography>
										<Typography component='span' sx={{fontSize: '12px',marginRight:'10px'}}>Home</Typography>
									</Link>
								</Grid>
							</Grid>
														
						<CardContent>
							<form onSubmit={this.handleSubmit}>
								<Grid container spacing={2}>
									{(this.state.displayform === true)?
										<Grid item sm={9}>
												<Grid container spacing={2} className='p-1'>
													<Grid item sm={6} xs={12}>
														<FormControl fullWidth>
															<InputLabel id="site">Site</InputLabel>
															<Select label="site" onChange={this.onChangeSite} defaultValue='' >
																{ sites.map(item=>
																	<MenuItem value={item.site_code} key={item.site_code}>{item.site_name}</MenuItem>
																)}
															</Select>
														</FormControl>
													</Grid>
													<Grid item sm={6} xs={12}>
														<TextField type="date" value={date} variant='outlined' fullWidth onChange={this.onChangeDate}/>
													</Grid>
												</Grid>
												<Divider textAlign="left">Domestic/Local</Divider>
												<Grid container spacing={2} className='p-1'>
													<Grid item sm={6} xs={12}>
														<TextField type="number" fullWidth  label="No. of Adult" value={this.state.adult}
															InputProps={{ inputProps: { min: 0} }}  onChange={this.onChangeAdultCount}/>
													</Grid>
													<Grid item sm={6} xs={12}>
														<TextField type="number" fullWidth label="No. of Children" value={this.state.children}  
															InputProps={{ inputProps: { min: 0} }} onChange={this.onChangeChildrenCount}/>
													</Grid>
												</Grid>
												<Divider textAlign="left">Foreign</Divider>
												<Grid container spacing={2} className='p-1'>
													<Grid item sm={6} xs={12}>
														<TextField type="number" fullWidth  label="No. of Adult" value={this.state.fadult}
															InputProps={{ inputProps: { min: 0} }}  onChange={this.onChangeFAdultCount}/>
													</Grid>
													<Grid item sm={6} xs={12}>
														<TextField type="number" fullWidth  label="No. of Children" value={this.state.fchildren}  
															InputProps={{ inputProps: { min: 0} }}  onChange={this.onChangeFChildrenCount}/>
													</Grid>
												</Grid>
												<Divider textAlign="left" sx={{display:'none'}}>Optional</Divider>
												<Grid container spacing={2} className='p-1' sx={{display:'none'}}>
													<Grid item sm={6} xs={12}>
														<TextField fullWidth label="Mobile Number" inputProps={{ maxLength: 12 }}
															onChange={this.onChangeMobile}/>
													</Grid>
													<Grid item sm={6}></Grid>
												</Grid>

												<Divider>Summary</Divider>
												<Grid container spacing={2} sx={{backgroundColor: '#E8F8F5',padding:'20px'}}>
													<Grid item sm={6}></Grid>
													<Grid item sm={6} xs={12}>
														<List component="nav" aria-label="mailbox folders">
															<ListItem button secondaryAction={total}>
																<ListItemText primary={"Total Count "} />
															</ListItem>
															<ListItem button secondaryAction={"Rs."+this.state.amount}>
																<ListItemText primary={"Total Amount "} />
															</ListItem>
														</List>
														<div className='text-right'>
															<Button variant="contained" type="button" sx={{marginRight:'10px'}} onClick={this.resetForm}>Reset</Button>
															<Button variant="contained" type="button" color="success" onClick={this.showConfirm}>Save Ticket</Button>
															<Button  ref={input => this.inputElement = input} variant="contained" color="success" sx={{display:'none'}}>
																<Link to={`/print/${this.state.ep_ref_no}`}>Link</Link>
															</Button>
														</div>
													</Grid>
												</Grid>
										</Grid>
										:
										<Grid item sm={9} xs={12}>
											<Typography gutterBottom variant="h6" component="div" className='page-title'>
												Ticket Confirmation
											</Typography>								
											<Table aria-label="simple table" sx={{marginBottom:'10px',border: '1'}}>
												<TableHead>
													<TableRow sx={{border: 1,borderColor: 'lightblue',backgroundColor: '#ECF0F1'}}>
														<TableCell sx={{textAlign:'center',borderLeft:1,borderColor:'lightblue'}}>Type</TableCell>
														<TableCell sx={{textAlign:'center',border: 1,borderColor: 'lightblue'}}>Rate(<MdCurrencyRupee/>)</TableCell>
														<TableCell sx={{textAlign:'center',border: 1,borderColor: 'lightblue'}}>No.</TableCell>
														<TableCell sx={{textAlign:'center',borderRight:1,borderColor:'lightblue'}}>Amt(<MdCurrencyRupee/>)</TableCell>
													</TableRow>
												</TableHead>
												<TableBody>
													{(adult>0)?
													(<TableRow>
														<TableCell sx={{textAlign:'center',borderLeft:1,borderColor:'lightblue'}}>Adult</TableCell>
														<TableCell sx={{textAlign:'center',border: 1,borderColor: 'lightblue'}}>{rates.DA}</TableCell>
														<TableCell sx={{textAlign:'center',border: 1,borderColor: 'lightblue'}}>{adult}</TableCell>
														<TableCell sx={{textAlign:'right',borderRight:1,borderColor:'lightblue',paddingRight:'10px'}}>{adult * rates.DA}</TableCell>
													</TableRow>) :<></>
													}													
													{(children>0)?
													(<TableRow>
														<TableCell sx={{textAlign:'center',borderLeft:1,borderColor:'lightblue'}}>Child</TableCell>
														<TableCell sx={{textAlign:'center',border: 1,borderColor: 'lightblue'}}>{rates.DC}</TableCell>
														<TableCell sx={{textAlign:'center',border: 1,borderColor: 'lightblue'}}>{children}</TableCell>
														<TableCell sx={{textAlign:'right',borderRight:1,borderColor:'lightblue',paddingRight:'10px'}}>{children * rates.DC}</TableCell>
													</TableRow>) :<></>
													}													
													{(fadult>0)?
													(<TableRow>
														<TableCell sx={{textAlign:'center',borderLeft:1,borderColor:'lightblue'}}>Adult(Foreigner)</TableCell>
														<TableCell sx={{textAlign:'center',border: 1,borderColor: 'lightblue'}}>{rates.FA}</TableCell>
														<TableCell sx={{textAlign:'center',border: 1,borderColor: 'lightblue'}}>{fadult}</TableCell>
														<TableCell sx={{textAlign:'right',borderRight:1,borderColor:'lightblue',paddingRight:'10px'}}>{fadult * rates.FA}</TableCell>
													</TableRow>) :<></>
													}													
													{(fchildren>0)?
													(<TableRow>
														<TableCell sx={{textAlign:'center',borderLeft:1,borderColor:'lightblue'}}>Child(Foreigner)</TableCell>
														<TableCell sx={{textAlign:'center',border: 1,borderColor: 'lightblue'}}>{rates.FC}</TableCell>
														<TableCell sx={{textAlign:'center',border: 1,borderColor: 'lightblue'}}>{fchildren}</TableCell>
														<TableCell sx={{textAlign:'right',borderRight:1,borderColor:'lightblue',paddingRight:'10px'}}>{fchildren * rates.FC}</TableCell>
													</TableRow>) :<></>
													}													
												</TableBody>
												<TableFooter>
													<TableRow>
														<TableCell colSpan={2} sx={{fontSize:'14px',fontWeight:'bold',textAlign:'center',borderLeft:1,borderColor:'lightblue'}}>Total</TableCell>
														<TableCell sx={{fontSize:'14px',fontWeight:'bold',textAlign:'center',border: 1,borderColor: 'lightblue'}} >{total}</TableCell>
														<TableCell sx={{fontSize:'14px',fontWeight:'bold',textAlign:'right',borderRight:1,borderColor:'lightblue',paddingRight:'10px'}}><MdCurrencyRupee/>{amount}</TableCell>
													</TableRow>
												</TableFooter>
											</Table>
											<Grid container spacing={6}>
												<Grid item sm={8} xs={5}></Grid>
												<Grid item sm={2} xs={3} textAlign={'right'}>
													<Button variant="contained" type="button" onClick={()=>{this.setState({displayform:true})}}>Back</Button>
												</Grid>
												<Grid item sm={2} xs={3} textAlign={'right'}>
													<Button variant="contained" type="submit" color="success">Confirm</Button>
												</Grid>
											</Grid>
										</Grid>
									}

									<Grid item md={3} xs="12">
										<Card sx={{backgroundColor:'#EDF4FD'}}>
											<Typography gutterBottom 
											sx={{textAlign:'center',fontSize:'18px',backgroundColor:'#DDECFE'}}>
												Rates
											</Typography>
											<List component="nav" aria-label="mailbox folders" sx={{fontSize:'12px'}}>
												<Divider>Domestic/Local</Divider>
												<ListItem button secondaryAction={
													<IconButton sx={{fontSize:'14px'}}><MdCurrencyRupee/>
														{rates.DA}
													</IconButton>
													}>
													<ListItemText secondary={"Adult "} />
												</ListItem>
												<Divider />
												<ListItem button secondaryAction={
													<IconButton sx={{fontSize:'14px'}}><MdCurrencyRupee/>
														{rates.DC}
													</IconButton>
													}>
													<ListItemText secondary={"Children "} />
												</ListItem>
												<Divider>Foreign</Divider>
												<ListItem button secondaryAction={
													<IconButton sx={{fontSize:'14px'}}><MdCurrencyRupee/>
														{rates.FA}
													</IconButton>
													}>
													<ListItemText secondary={"Adult "} />
												</ListItem>
												<Divider />
												<ListItem button secondaryAction={
													<IconButton sx={{fontSize:'14px'}}><MdCurrencyRupee/>
														{rates.FC}
													</IconButton>
													}>
													<ListItemText secondary={"Children "} />
												</ListItem>
												<Divider />
											</List>
										</Card>
									</Grid>
								</Grid>
							</form>
						</CardContent>
					</Card>
				</Container>
  			</>
		)
	}

} 
export default SelfEntryComponent