import * as React from 'react';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

/** Custom Components */
import { Container } from 'react-bootstrap'; 
import { CHANGE_PWD_URL } from '../config/config';
import NavComponent from './nav';
import { Grid } from '@mui/material';
import { sha256 } from 'js-sha256';

class PwdComponent extends React.Component {  

	constructor(props){
		super(props);
		this.state = {
			currentpwd : '',
			newpwd : '',
			confirmpwd : ''
		}
		//this.reprintTicket = this.reprintTicket.bind(this);
	}

	componentDidMount = () =>{
		
	}

	onChangeCP = (event) =>{
		this.setState({currentpwd : event.target.value});
	}
	onChangeNP = (event) =>{
		this.setState({newpwd : event.target.value});
	}
	onChangeCNP = (event) =>{
		this.setState({confirmpwd : event.target.value});
	}
	hasThreeIdenticalChars = (password) =>{
		alert(password)
		for (let i = 0; i < password.length - 2; i++) {
			if (password[i] === password[i+1] && password[i] === password[i+2]) {
				return true;
			}
		}
		return false;
	}
	
	hasThreeConsecutiveCharacters = (password) => {
		alert(password)
		password = password.toLowerCase(); // Convert to lowercase for simplicity
		for (let i = 0; i < password.length - 2; i++) {
			if (password.charCodeAt(i) + 1 === password.charCodeAt(i + 1) && password.charCodeAt(i + 1) + 1 === password.charCodeAt(i + 2)) {
				return true; // Three consecutive characters found
			}
		   if (password.charCodeAt(i) - 1 === password.charCodeAt(i - 1) && password.charCodeAt(i - 1) - 1 === password.charCodeAt(i - 2)) {
				return true; // Three consecutive characters found
			}
		}
		return false; // No three consecutive characters found
	}
	submitForm = () =>{
		if(this.state.currentpwd.length === 0 ||this.state.newpwd.length === 0 || this.state.confirmpwd.length === 0){
			alert("Password too short!");
			return false;
		}
		var regex = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
		if(!regex.test(this.state.newpwd)){
			alert('Invalid Password. Refer to the instruction below.');
			this.setState({currentpwd:'',confirmpwd:'',newpwd:''});
			return false;
		}
		if(this.state.newpwd !== this.state.confirmpwd){
			alert("Passwords do not match!");
			this.setState({currentpwd:'',confirmpwd:'',newpwd:''});
			return false;
		}

		if(this.hasThreeIdenticalChars(this.state.newpwd) || this.hasThreeConsecutiveCharacters(this.state.newpwd)){
			alert("Passwords cannot contain consecutive identical/successive numbers!");
			this.setState({currentpwd:'',confirmpwd:'',newpwd:''});
			return false;
		}

		let currentpwd = sha256(this.state.currentpwd);
		let newpwd = sha256(this.state.newpwd);
		let confirmpwd = sha256(this.state.confirmpwd);
		let data = JSON.stringify({
			cp: currentpwd ,
			np: newpwd,
			cnp: confirmpwd,
			user : localStorage.getItem('user'),
			site : localStorage.getItem('site')
		})
		fetch(CHANGE_PWD_URL,{
				method:'POST',
				headers: {
					'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
					'Content-type' : 'application/json',
					'Checksum' : sha256(data)
				},
				body : JSON.stringify({ 'data' : btoa(data)})
			})
			.then(res => res.json())
			.then(res => {
				if(res.STATUS === 0){
					alert("Password changed!");
					this.props.history(`/`);
				}
				else
					alert(res.MESSAGE);
					this.setState({newpwd : '',currentpwd:'',confirmpwd:''});
				})
			.catch(error => {
				alert('Error:: Unable to change password!!');
				this.setState({newpwd : '',currentpwd:'',confirmpwd:''});
			})
	}


	render() {  
		return (
			<>
				<NavComponent active="pwd"/>
				<Container>
					<Card sx={{ padding: 2, minHeight: '80vh' }}>
						<Typography gutterBottom variant="h6" component="div" className='page-title'>Change Password</Typography>								
						<Grid container spacing={2}>
							<Grid item md={12} xs={12}>
								<Grid container spacing={2} className="m-1">
									<Grid item md={4}></Grid>
									<Grid item xs={10} md={4}>
										<TextField type="password" fullWidth label="Current Password" value={this.state.currentpwd} onChange={this.onChangeCP}/>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="m-1">
									<Grid item md={4}></Grid>
									<Grid item xs={10} md={4}>
										<TextField type="password" fullWidth label="New Password" value={this.state.newpwd} onChange={this.onChangeNP}/>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="m-1">
									<Grid item md={4}></Grid>
									<Grid item xs={10} md={4}>
										<TextField type="password" fullWidth label="Confirm New Password" value={this.state.confirmpwd} onChange={this.onChangeCNP}/>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="m-1">
									<Grid item md={8} textAlign={'right'}>
									<Button  variant="contained" color="success" sx={{margin:'10px'}} onClick={this.submitForm}>Save Changes</Button>
									</Grid>
								</Grid>
								<Grid container spacing={2} className="m-1">
									<Grid item md={4}></Grid>
									<Grid item xs={10} md={4}>
										Password instructions : 
										<ul>
											<li>At least one capital letter, A-Z</li>
											<li>At least one small letter, a-z</li>
											<li>At least one digit, [0-9]</li>
											<li>At least one special character #?!@$%^&*- </li>
											<li>Minimum eight in length </li>
											<li>Cannot contain successive characters eg. 123,321,abc  </li>
											<li>Cannot contain identical characters eg. 111,aaa </li>
										</ul>
									</Grid>
								</Grid>

							</Grid>
						</Grid>
					</Card>
				</Container>
  			</>
		)
	}

} 
export default PwdComponent