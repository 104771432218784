import * as React from 'react';
import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import {  Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';

/** Custom Components */
import { CHANGE_STATUS_URL, RESET_PWD_URL, USERS_URL } from '../config/config';
import { Link } from 'react-router-dom';
import { sha256 } from 'js-sha256';

class UserComponent extends React.Component {  

	constructor(props){
		super(props);
		this.state = {
			site : localStorage.getItem("site"),
			users : []
		}
	}

	componentDidMount = () =>{
		this.getUsers();
		
	}

	getUsers = () =>{
		let data = JSON.stringify({site: this.state.site});
		fetch(USERS_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum': sha256(data)
			},
			body: JSON.stringify({"data": btoa(data)})
		})
		.then(res => res.json())
		.then(res => {
			this.setState({
					users : res.DATA
				});
		})
		.catch(error => {
			//alert('Error in loading data !');
		})
	}
	onChangeName = (event) =>{
		this.setState({name : event.target.value});
	}
	onChangeDescription = (event) =>{
		this.setState({description : event.target.value});
	}

	resetPassword = (event) =>{
		let user = event.target.id;
		if (window.confirm("Are you sure to reset password for selected user?") === true){
			let data = JSON.stringify({
				user : user,
				site : localStorage.getItem('site')
			});
			fetch(RESET_PWD_URL,{
				method:'POST',
				headers: {
					'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
					'Content-type' : 'application/json',
					'Checksum' : sha256(data)
				},
				body : JSON.stringify({"data":btoa(data)})
			})
			.then(res => res.json())
			.then(res => {
				if(res.STATUS === 0){
					alert("Password for selected user reset to the username "+user );
				}
				else
					alert(res.MESSAGE);
				})
			.catch(error => {
				alert('Error:: Unable to save data!!');
			})
		}

	}	
	changeStatus = (event) =>{
		let user = event.target.id;
		if (window.confirm("Are you sure to change status for selected user?") === true){
			let data = JSON.stringify({
				user : user,
				site : localStorage.getItem('site')
			});
			fetch(CHANGE_STATUS_URL,{
				method:'POST',
				headers: {
					'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
					'Content-type' : 'application/json',
					'Checksum' : sha256(data)
				},
				body : JSON.stringify({"data":btoa(data)})
			})
			.then(res => res.json())
			.then(res => {
				if(res.STATUS === 0){
					alert("Successfully updated !" );
					this.getUsers();
				}
				else
					alert(res.MESSAGE);
				})
			.catch(error => {
				alert('Error:: Unable to save data!!');
			})
		}

	}


	render() {  
		const { users} = this.state;
		return (
			<>
				<Card>
					<Typography gutterBottom variant="h6" component="div" textAlign={'center'}>Manage Users</Typography>								
					<Table>
						<TableHead>
							<TableRow>
								<TableCell></TableCell>
								<TableCell>Username</TableCell>
								<TableCell>Name</TableCell>
								<TableCell>Committee</TableCell>
								<TableCell>Status</TableCell>
								<TableCell>Reset Password</TableCell>
								<TableCell>Change</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{
								users.map((item,key)=>
								<TableRow>
									<TableCell>{(key+1)}</TableCell>
									<TableCell>{item.username}</TableCell>
									<TableCell>{item.name}</TableCell>
									<TableCell>{item.committee}</TableCell>
									<TableCell>{(item.status === 'A')?'Active':'Inactive'}</TableCell>
									<TableCell>
										<Link onClick={this.resetPassword} id={item.username}> Reset Password </Link>
									</TableCell>
									<TableCell>
										<Link onClick={this.changeStatus} id={item.username}>{(item.status === 'A')?'Deactivate':'Activate'}</Link>
									</TableCell>
								</TableRow>
								)
							}
						</TableBody>
					</Table>
				</Card>
  			</>
		)
	}

} 
export default UserComponent