import * as React from 'react';

import Card from '@mui/material/Card';
import Typography from '@mui/material/Typography';
import ReactToPrint from 'react-to-print';
import Button from '@mui/material/Button';

/** Custom Components */
import { Container } from 'react-bootstrap'; 
import { FormControl, Grid, InputLabel, List, ListItem, ListItemButton, ListItemIcon, ListItemText, MenuItem, Select, TextField } from '@mui/material';
import { MdToday,MdOutlineSummarize, MdPrint } from 'react-icons/md';
import NavComponent from './nav';
import { DAILY_TICKETS_URL,MONTHLY_SUMMARY_URL,MONTHLIST, SPECIFIC_SUMMARY_URL, SITE_DETAIL_URL, SCANSUMMARY_URL } from '../config/config';
import ByDate from './reportbydate';
import MonthlySummary from './monthlysummary';
import Monthwise from './monthwise';
import Userwise from './userwise';
import Datewise from './datewise';
import ScanReport from './scanreport';
import ScanUserwise from './scanuserwise';
import { sha256 } from 'js-sha256';


class ReportComponent extends React.Component {  

	constructor(props){
		super(props);
		let today = new Date().getFullYear()+'-'+String(new Date().getMonth()+1).padStart(2,'0')+'-'+String(new Date().getDate()).padStart(2,'0');
		this.state = {
			site : {},
			date : today,
			fromdate : today,
			todate : today,
			sdate : '',
			sfromdate : '',
			stodate : '',
			month : new Date().getMonth(),
			year : new Date().getFullYear(),
			monthlist : MONTHLIST,
			datewise : true,
			monthly : false,
			specific : false,
			scan : false,
			data : [],
			userwise : [],
			monthwise : [],
			datewisedata : [],
			length : 0,
			count : 0,
			sum : 0,
			activemenu: 1
		}
	}

	componentDidMount = () => {
		this.getSiteDetail();
		this.getDatewiseSummary();
	}

	onChangeMonth = (event) =>{
		this.setState({month : event.target.value})
	}

	onChangeYear = (event) =>{
		this.setState({year : event.target.value})
	}
	onChangeDate = (event) =>{
		this.setState({date: event.target.value});
	}
	onChangeFromDate = (event) =>{
		this.setState({fromdate: event.target.value});
	}
	onChangeToDate = (event) =>{
		this.setState({todate: event.target.value});
	}
	
	formatDMY = (ymd) =>{
		return (ymd.substr(8,2)+'-'+ymd.substr(5,2)+'-'+ymd.substr(0,4));
	}

	formatMDY = (ymd) =>{
		return (ymd.substr(5,2)+'-'+ymd.substr(8,2)+'-'+ymd.substr(0,4));
	}
	/** Site Details */
	getSiteDetail = () =>{
		let data = JSON.stringify({site: localStorage.getItem('site')});
		fetch(SITE_DETAIL_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum' : sha256(data)
			},
			body : JSON.stringify({"data":btoa(data)})

		})
		.then(res => res.json())
		.then(res => {
			if(res.STATUS === 0){
				this.setState({site: res.DATA})
				return false;
			}
			else{
				alert("Error getting site information");
			}
		})
		.catch(error => {
			alert('Error !');
			return false;
		})

	}

	getDatewiseSummary = () =>{
		const {date} = this.state;
		let dmydate = this.formatDMY(date);
		this.setState({
			activemenu: 1,
			sdate: dmydate
		});
		
		let data = JSON.stringify({
			user : localStorage.getItem('user'), 
			site : localStorage.getItem('site'), 
			date : dmydate
		});
		fetch(DAILY_TICKETS_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum' : sha256(data)
			},
			body : JSON.stringify({"data": btoa(data)})

		})
		.then(res => res.json())
		.then(res => {
			if(res.DATA){
				this.setState( {
					datewise : true,
					monthly : false,
					specific : false,
					scan : false,
					data : res.DATA,
					length : res.LENGTH,
					count : res.COUNT,
					sum : res.SUM
				});
			}
		})
		.catch(error => {
			alert('Error in loading summary !');
		})
	}

	getMonthly = () =>{
		this.setState({
			activemenu: 2,
			month: String(this.state.month).padStart(2,'0')
		});
		
		let data = JSON.stringify({
			site : this.state.site.site_code,
			user : localStorage.getItem('user'),
			month : this.state.month,
			year : this.state.year
		})
		fetch(MONTHLY_SUMMARY_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum' : sha256(data)
			},
			body : JSON.stringify({"data" : btoa(data)})

		})
		.then(res => res.json())
		.then(res => {
			if(res.DATA){
				this.setState( {
					datewise : false,
					monthly : true,
					specific : false,
					scan : false,
					data : res.DATA,
					length : res.LENGTH,
					count : res.COUNT,
					sum : res.SUM
				});
			}
		})
		.catch(error => {
			alert('Error in loading summary !');
		})
	}
	
	getSpecific = () =>{
		const {fromdate, todate} = this.state;
		this.setState({
			activemenu: 3,
			sfromdate: this.formatDMY(fromdate),
			stodate: this.formatDMY(todate)
		});
		
		let data = JSON.stringify({
			site : this.state.site.site_code,
			user : localStorage.getItem('user'),
			fromdate : this.formatMDY(fromdate),
			todate : this.formatMDY(todate)
		});
		fetch(SPECIFIC_SUMMARY_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum' : sha256(data)
			},
			body : JSON.stringify({"data" : btoa(data)})

		})
		.then(res => res.json())
		.then(res => {
			if(res.USERWISE){
				this.setState({
					datewise : false,
					monthly : false,
					specific : true,
					scan : false,
					userwise : res.USERWISE,
					monthwise : res.MONTHWISE,
					datewisedata : res.DATEWISE,
					length : res.LENGTH,
					count : res.COUNT,
					sum : res.SUM
				});
			}
		})
		.catch(error => {
			alert('Error in loading data !');
		})
	}

	getScanReport = () =>{
		const {fromdate, todate} = this.state;
		this.setState({
			activemenu: 4,
			sfromdate: this.formatDMY(fromdate),
			stodate: this.formatDMY(todate)
		});
		
		let data = JSON.stringify({
			site : this.state.site.site_code,
			user : localStorage.getItem('user'),
			fromdate : this.formatMDY(fromdate),
			todate : this.formatMDY(todate)
		});
		fetch(SCANSUMMARY_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum' : sha256(data)
			},
			body : JSON.stringify({"data": btoa(data)})

		})
		.then(res => res.json())
		.then(res => {
			if(res.DATA){
				this.setState({
					datewise : false,
					monthly : false,
					specific : false,
					scan : true,
					data : res.DATA,
					userwise : res.USERWISE,
					length : res.LENGTH,
					count : res.COUNT,
					sum : res.SUM
				});
			}
		})
		.catch(error => {
			alert('Error in loading data !');
		})
	}

	handleClick = () => {
		if(this.state.datewise){
			this.getDatewiseSummary();
		}
		else if(this.state.monthly){
			this.getMonthly();
		}
		else if(this.state.specific){
			this.getSpecific();
		}
		else if(this.state.scan){
			this.getScanReport();
		}

	}

	render() {  
		const { date, monthlist,fromdate,todate } = this.state;
		return (
			<>
				<NavComponent active="report"/>
				<Container>
					<Card sx={{ minWidth: 275, padding: 2, minHeight: '80vh',overflow:'auto'  }}>
						<Typography gutterBottom variant="h6" component="div" className='page-title'>Report</Typography>								
						<Grid container spacing={2}>
							<Grid item md={3} xs={12}>
								<List>
									<ListItem disablePadding className={((this.state.activemenu === 1)?'activemenu':'')} sx={{borderBottom: 1, borderColor: 'divider'}}>
										<ListItemButton onClick={this.getDatewiseSummary}>
											<ListItemIcon>
												<MdOutlineSummarize />
											</ListItemIcon>
											<ListItemText primary="Daily Summary" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding className={((this.state.activemenu === 2)?'activemenu':'')} sx={{borderBottom: 1, borderColor: 'divider'}}>
										<ListItemButton onClick={this.getMonthly}>
										<ListItemIcon>
											<MdToday />
										</ListItemIcon>
										<ListItemText primary="Statement by Month" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding className={((this.state.activemenu === 3)?'activemenu':'')} sx={{borderBottom: 1, borderColor: 'divider'}}>
										<ListItemButton onClick={this.getSpecific}>
										<ListItemIcon>
											<MdToday />
										</ListItemIcon>
										<ListItemText primary="Statement by Date" />
										</ListItemButton>
									</ListItem>
									<ListItem disablePadding className={((this.state.activemenu === 4)?'activemenu':'')} sx={{borderBottom: 1, borderColor: 'divider'}}>
										<ListItemButton onClick={this.getScanReport}>
										<ListItemIcon>
											<MdToday />
										</ListItemIcon>
										<ListItemText primary="Ticket Scan Report" />
										</ListItemButton>
									</ListItem>
								</List>
							</Grid>
							<Grid item md={9} xs={13} className='mt-3'>
								{(this.state.datewise)?
								<>
									<Grid container spacing={2}>
										<Grid item sm={1} xs={2}>Date</Grid>
										<Grid item sm={3} xs={9}>
											<TextField type="date" size='small' value={date} variant='outlined' fullWidth onChange={this.onChangeDate}/>
										</Grid>
										<Grid item sm={2} xs={7} sx={{textAlign:'right'}}>
											<Button variant="contained" color="success" onClick={this.handleClick}>Load</Button>
										</Grid>
										<Grid item sm={2} xs={4} sx={{textAlign:'right'}}>
											<ReactToPrint
												trigger={() => {
													return <Button variant="contained" color="success">Print<div className="printicon"><MdPrint/></div></Button>;
												}}
												content={() => this.summaryRef} />
										</Grid>
									</Grid>
									<ByDate data={this.state} ref={el => (this.summaryRef = el)} />
								</>:<></>}

								{(this.state.monthly)?
								<>
									<Grid container spacing={2}>
										<Grid item sm={3} xs={5}>
											<FormControl fullWidth>
												<InputLabel id="month" size='small'>Month</InputLabel>
												<Select label="Month" onChange={this.onChangeMonth} size='small'>
													{ monthlist.map(item=>
														<MenuItem value={item.val}>{item.name}</MenuItem>
													)}
												</Select>
											</FormControl>
										</Grid>
										<Grid item sm={3} xs={5}>
										<FormControl fullWidth>
											<InputLabel id="year" size='small'>Year</InputLabel>
											<Select label="Year" onChange={this.onChangeYear} size='small'>
												<MenuItem value='2023' selected>2023</MenuItem>
												<MenuItem value='2024' selected>2024</MenuItem>
											</Select>
										</FormControl>
										</Grid>
										<Grid item sm={2} xs={7} sx={{textAlign:'right'}}>
											<Button variant="contained" color="success" onClick={this.handleClick}>Load</Button>
										</Grid>
										<Grid item sm={2} xs={4} sx={{textAlign:'right'}}>
										<ReactToPrint
											trigger={() => {
												return <Button variant="contained" color="success">Print<div className="printicon"><MdPrint/></div></Button>;
											}}
											content={() => this.monthlyRef}
										/>
										</Grid>
									</Grid>
									<MonthlySummary data={this.state} ref={el => (this.monthlyRef = el)} />
								</>:<></>}
								{(this.state.specific)?
								<>
									<Grid container spacing={2}>
										<Grid item sm={1} xs={2} sx={{padding:'3px'}}>From</Grid>
										<Grid item sm={3} xs={9}>
											<TextField type="date" size='small' value={fromdate} variant='outlined' fullWidth onChange={this.onChangeFromDate}/>
										</Grid>
										<Grid item sm={1} xs={2} sx={{padding:'3px'}}>To</Grid>
										<Grid item sm={3} xs={9}>
											<TextField type="date" size='small' value={todate} variant='outlined' fullWidth onChange={this.onChangeToDate}/>
										</Grid>
										<Grid item sm={1} xs={6} sx={{textAlign:'right'}}>
											<Button variant="contained" color="success" onClick={this.handleClick}>Load</Button>
										</Grid>
										<Grid item sm={3} xs={4} sx={{textAlign:'center'}}>
											<ReactToPrint
												trigger={() => {
													return <Button variant="contained" color="success">Datewise <div className="printicon"><MdPrint/></div> </Button>;
												}}
												content={() => this.datewiseRef}
											/>
										</Grid>
									</Grid>
									<Datewise data={this.state} ref={el => (this.datewiseRef = el)} />
									<Grid container spacing={2}>
										<Grid item sm={11} xs={11} sx={{textAlign:"right",marginTop:'10px'}}>
											<ReactToPrint
												trigger={() => {
													return <Button variant="contained" color="success">Monthwise <div className="printicon"><MdPrint/></div> </Button>;
												}}
												content={() => this.monthwiseRef}
											/>
										</Grid>
									</Grid>
									<Monthwise data={this.state} ref={el => (this.monthwiseRef = el)} />
									<Grid container spacing={2}>
										<Grid item sm={11} xs={11}  sx={{textAlign:"right",marginTop:'10px'}}>
											<ReactToPrint
												trigger={() => {
													return <Button variant="contained" color="success">Operatorwise <div className="printicon"><MdPrint/></div> </Button>;
												}}
												content={() => this.userwiseRef}
											/>
										</Grid>
									</Grid>
									<Userwise data={this.state} ref={el => (this.userwiseRef = el)} />
								</>:<></>}
								{(this.state.scan)?
								<>
									<Grid container spacing={2}>
										<Grid item sm={1} xs={2} sx={{padding:'3px'}}>From</Grid>
										<Grid item sm={3} xs={9}>
											<TextField type="date" size='small' value={fromdate} variant='outlined' fullWidth onChange={this.onChangeFromDate}/>
										</Grid>
										<Grid item sm={1} xs={2} sx={{padding:'3px'}}>To</Grid>
										<Grid item sm={3} xs={9}>
											<TextField type="date" size='small' value={todate} variant='outlined' fullWidth onChange={this.onChangeToDate}/>
										</Grid>
										<Grid item sm={1} xs={7} sx={{textAlign:'right'}}>
											<Button variant="contained" color="success" onClick={this.handleClick}>Load</Button>
										</Grid>
										<Grid item sm={2} xs={4} sx={{textAlign:'right'}}>
											<ReactToPrint
												trigger={() => {
													return <Button variant="contained" color="success">Print <div className="printicon"><MdPrint/></div> </Button>;
												}}
												content={() => this.datewiseRef}
											/>
										</Grid>
									</Grid>
									<ScanReport data={this.state} ref={el => (this.datewiseRef = el)} />
									<Grid container spacing={2}>
										<Grid item sm={11} xs={11}  sx={{textAlign:"right",marginTop:'10px'}}>
											<ReactToPrint
												trigger={() => {
													return <Button variant="contained" color="success">Operatorwise <div className="printicon"><MdPrint/></div> </Button>;
												}}
												content={() => this.scanuserRef}
											/>
										</Grid>
									</Grid>
									<ScanUserwise data={this.state} ref={el => (this.scanuserRef = el)} />
								</>:<></>}
							</Grid>
						</Grid>
					</Card>
				</Container>
  			</>
		)
	}

} 
export default ReportComponent