import * as React from 'react';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

/** Custom Components */
import { Container } from 'react-bootstrap'; 
import { LAST_TICKETS_URL, TICKET_REPRINT_URL } from '../config/config';
import NavComponent from './nav';
import { Grid } from '@mui/material';
import { MdCurrencyRupee } from 'react-icons/md';
import { sha256 } from 'js-sha256';

class ReprintComponent extends React.Component {  

	constructor(props){
		super(props);
		this.state = {
			tickets : [],
			ep_ref_no : ''
		}
		this.reprintTicket = this.reprintTicket.bind(this);
	}

	componentDidMount = () =>{
		let data = JSON.stringify({count : 10,user : localStorage.getItem('user'),site : localStorage.getItem('site')});
		fetch(LAST_TICKETS_URL,{
			method:'POST',
			headers: {
				'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
				'Content-type' : 'application/json',
				'Checksum' : sha256(data)
			},
			body : JSON.stringify({"data":btoa(data)})

		})
		.then(res => res.json())
		.then(res => {
			this.setState({tickets: res.DATA});
		})
		.catch(error => {
			alert('Error in loading data!!');
		})
	}

	onChangeRefNo = (event) =>{
		this.setState({ep_ref_no : event.target.value});
	}

	reprintTicket = () =>{
		let ep_ref_no = this.state.ep_ref_no;
		let data = JSON.stringify({
			ep_ref_no: ep_ref_no ,
			user : localStorage.getItem('user'),
			site : localStorage.getItem('site')
		});
		fetch(TICKET_REPRINT_URL,{
				method:'POST',
				headers: {
					'Authorization' : localStorage.getItem('ACCESS_TOKEN'),
					'Content-type' : 'application/json',
					'Checksum' : sha256(data)
				},
				body : JSON.stringify({"data":btoa(data)})
			})
			.then(res => res.json())
			.then(res => {
				if(res.STATUS === 0)
					this.props.history(`/print/${ep_ref_no}`);
				else
					alert(res.MESSAGE);
			})
			.catch(error => {
				alert('Error:: Unable to find ticket!!');
			})
	}


	render() {  
		const { tickets } = this.state;
		return (
			<>
				<NavComponent active="reprint"/>
				<Container>
					<Card sx={{ padding: 2, minHeight: '80vh',overflow:'auto' }}>
						<Typography gutterBottom variant="h6" component="div" className='page-title'>Reprint Ticket</Typography>								
						<Grid container spacing={2}>
							<Grid item md={8}>
								<Box component="form" noValidate autoComplete="off" >
									<Divider textAlign="left"></Divider>
									<Grid container spacing={2} className="m-1">
										<Grid item md={1}></Grid>
										<Grid item sm={8} md={4}>
											<TextField fullWidth label="Entry reference No" defaultValue="" onChange={this.onChangeRefNo}/>
										</Grid>
										<Grid item sm={2}>
											<Button  variant="contained" color="success" sx={{margin:'10px'}} onClick={this.reprintTicket}>Print</Button>
										</Grid>
									</Grid>
								</Box>
							</Grid>
						</Grid>
						<Grid container spacing={2}>
							<Grid item sm={1}></Grid>
							<Grid item sm={8}>
								<Table aria-label="simple table" sx={{marginTop:'10px'}}>
									<TableHead>
										<TableRow>
											<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'center'}}></TableCell>
											<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'center'}}>Ticket No</TableCell>
											<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'center'}}>Count</TableCell>
											<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'center'}}>Amount</TableCell>
											<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'center'}}>Timestamp</TableCell>
											<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'center'}}>Operator</TableCell>
										</TableRow>
									</TableHead>
									<TableBody sx={{overflowX:'auto'}}>
										{
											tickets.map((item,key)=>(
												<TableRow key={key}>
													<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'center'}}>{key+1}</TableCell>
													<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'center'}}>{item.ep_ref_no}</TableCell>
													<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'center'}}>{item.count}</TableCell>
													<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'right'}} align='right'> <MdCurrencyRupee/>{item.amount}</TableCell>
													<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'center'}}>{item.entry}</TableCell>
													<TableCell sx={{padding:'5px',border:1,borderColor: 'lightgray',textAlign:'center'}}>{item.entered_by}</TableCell>
												</TableRow>
												))
										}
										</TableBody>
								</Table>
							</Grid>
						</Grid>
					</Card>
				</Container>
  			</>
		)
	}

} 
export default ReprintComponent