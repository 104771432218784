// const BASE_URL = "http://localhost/passapi/public";
// const BASE_URL = "http://10.178.7.44/passapi/public";
//const BASE_URL = "http://164.100.149.93/passapi/public";
const BASE_URL = "https://touristpass.mn.gov.in/passapi/public";

export const REGISTER_URL = BASE_URL+'/register';
export const LOGIN_URL = BASE_URL+'/login';
export const GUEST_URL = BASE_URL+'/guest';
export const USERS_URL = BASE_URL+'/users';
export const CHANGE_PWD_URL = BASE_URL+'/changepwd';
export const RESET_PWD_URL = BASE_URL+'/resetpwd';
export const CHANGE_STATUS_URL = BASE_URL+'/status/update';

export const COMM_URL = BASE_URL+'/committee';
export const ADD_COMM_URL = BASE_URL+'/committee/add';
export const ROLES_URL = BASE_URL+'/roles';
export const RATES_URL = BASE_URL+'/site/rates';
export const SITES_URL = BASE_URL+'/site/all';
export const SITE_DETAIL_URL = BASE_URL+'/site/detail';

export const TICKET_DATA_URL = BASE_URL+'/ticket/detail';
export const TICKET_SAVE_URL = BASE_URL+'/ticket/save';
export const TICKET_REPRINT_URL = BASE_URL+'/ticket/reprint';


export const TICKET_VERIFY_URL = BASE_URL+'/ticket/verify';
export const PAYMENT_UPDATE_URL = BASE_URL+'/ticket/update';
export const LAST_TICKETS_URL = BASE_URL+'/ticket/last';

export const DAILY_TICKETS_URL = BASE_URL+'/report/daily/all';
export const DAILY_TICKETS_BY_USER_URL = BASE_URL+'/report/daily';
export const MONTHLY_SUMMARY_URL = BASE_URL+'/report/monthly';
export const SPECIFIC_SUMMARY_URL = BASE_URL+'/report/specific';
export const SCANSUMMARY_URL = BASE_URL+'/report/scan';


export const MONTHLIST = [
		{'val':'01', 'name': 'January'},
		{'val':'02', 'name': 'February'},
		{'val':'03', 'name': 'March'},
		{'val':'04', 'name': 'April'},
		{'val':'05', 'name': 'May'},
		{'val':'06', 'name': 'June'},
		{'val':'07', 'name': 'July'},
		{'val':'08', 'name': 'August'},
		{'val':'09', 'name': 'September'},
		{'val':'10', 'name': 'October'},
		{'val':'11', 'name': 'November'},
		{'val':'12', 'name': 'December'}
]
